<h2 mat-dialog-title>
  Notify Email
</h2>

<div mat-dialog-content>

  <dx-validation-group #targetGroup>
    <div class="dx-fieldset">
      <!--    <div class="dx-fieldset-header">Personal Info</div>-->
      <label>Code</label>
      <dx-select-box
        width="150px"
        [dataSource]="codes"
        [(value)]="code"
        valueExpr="_id"
        [displayExpr]="service.codeDisplayExpr"
        [showClearButton]="true"
        (onValueChanged)="onCodeChanged($event)"
      >
        <dx-validator name="Code">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>

      <br />

      <label>To</label>
      <dx-select-box
        #toSelect
        [dataSource]="emails"
        [(value)]="to"
        [acceptCustomValue]="true"
      >
        <dx-validator name="Email">
          <dxi-validation-rule type="required"></dxi-validation-rule>
          <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
      <dx-button type="default" text="Copy to Clipboard" (onClick)="copySelectToClipboard(toSelect)"></dx-button>

      <br /><br />

      <label>Subject</label>
      <dx-text-box #subjectInput [value]="subject"></dx-text-box>
      <dx-button type="default" text="Copy to Clipboard" (onClick)="copyToClipboard(subjectInput)"></dx-button>

      <br /><br />

      <label>Body</label>
      <dx-text-area
        #bodyInput
        [value]="body"
        [height]="200"
        [valueChangeEvent]='"keyup"'
      ></dx-text-area>
      <dx-button type="default" text="Copy to Clipboard" (onClick)="copyToClipboard(bodyInput)"></dx-button>

      <br /><br />

      <dx-validation-summary></dx-validation-summary>

    </div>
  </dx-validation-group>

</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Close</button>
  <!--  <button mat-button color="primary" (click)="submit()">Submit</button>-->
</div>

